import { get, post } from './http'

// 充电宝 - 订单列表
export const getPowerbankOrderList = (data) => post('/api/merchant/power/bank/order/list', { ...data, pageSize: 10 })

// 充电宝 - 提现
export const postPowerbankWithdraw = (data) => post('/api/merchant/power/bank/withdraw/applyWithdraw', data)

// 充电宝 - 提现 - 记录
export const getPowerbankWithdrawLogList = (data) => post('/api/merchant/power/bank/withdraw/userWithdrawLogList', data)

// 充电宝 - 获取客服配置
export const getCustomerServiceCondfig = () => get('/api/merchant/common/getCustomerServiceConfig')