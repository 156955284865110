<template>
  <div>
    <van-tabbar route active-color="#333333" inactive-color="#bfbfbf">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.index.active : icon.index.inactive" />
        </template>
      </van-tabbar-item>
       <van-tabbar-item replace to="/transactionList">
        <span>交易明细</span>
        <template #icon="props">
          <img :src="props.active ? icon.mine.active : icon.mine.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="userType != 2" replace to="/withdrawal">
        <span>提现</span>
        <template #icon="props">
          <img :src="
            props.active ? icon.withdrawal.active : icon.withdrawal.inactive
          " />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.mine.active : icon.mine.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: {
        index: {
          active: require("assets/images/tabbar/index1.png"),
          inactive: require("assets/images/tabbar/index0.png"),
        },
        withdrawal: {
          active: require("assets/images/tabbar/withdraw1.png"),
          inactive: require("assets/images/tabbar/withdraw0.png"),
        },
        mine: {
          active: require("assets/images/tabbar/mine1.png"),
          inactive: require("assets/images/tabbar/mine0.png"),
        },
      },
      userType: localStorage.getItem('userType')
    };
  },
  created() { },
  methods: {},
  components: {},
};
</script>

<style lang='less' scoped>
.van-tabbar-item__icon img {
  height: 24px;
  width: 24px;
}

.van-tabbar-item {
  font-size: 11px;
}

/deep/.van-tabbar {
  height: 52px !important;
}
</style>
