<template>
  <div>
    <van-tabs v-model="tabIndex" sticky color="#5d544f" title-active-color="#fd6e05">
      <van-tab title="充电宝提现" index="0">
        <div class="trade-withdraw">
          <div class="trade-withdraw__top">
            <img src="~assets/images/common/ic_money.png" />
            <div class="text">余额: ¥{{ merchantInfo?.pbWithdrawAbleAmount || 0 }}</div>
          </div>
          <div class="trade-withdraw__input-wrapper">
            <div class="text">¥</div>
            <van-field v-model="powerBankWidthdrawAmount" placeholder="请输入金额" size="large" type="number"
              :rules="[{ required: true, message: '请输入金额' }]" />
          </div>
          <div>
            <van-button round block native-type="submit" class="trade-withdraw__btn submit-btn dialog-btn"
              :class="powerBankWidthdrawAmount > 0 ? 'submit-btn3' : 'submit-btn2'"
              @click="onPowerbankWithdrawConfirm">提现</van-button>
          </div>
          <div class="text-tip_wrapper">
            <div class="text-tip">
              {{ withdrawConfigInfo }} 
              <!-- 手动提现手续费：5000以下<span class="text-tip_money">1</span>元一笔5000以上提现金额的<span class="text-tip_money">0.02%</span> -->
            </div>
          </div>
        </div>
        <div class="withdraw-log_title">
          <img src="~assets/images/common/line.png" alt />
          <span>提现记录</span>
        </div>
        <div class="withdraw-list">
          <!-- <div v-if="withdrawLogList.length > 0"> -->
          <div>
            <van-list v-model="powerBankLoading" :finished="powerBankFinished" :immediate-check="false" :offset="10"
              finished-text="没有更多了" @load="onPowerbankLoadMore">
              <div v-for="item in powerbankWithdrawLogList" :key="item.id" class="withdraw-log_item">
                <img src="~assets/images/common/ic_withdraw_log.png" />
                <div class="content-wrapper">
                  <div class="top-wrapper">
                    <div>提现</div>
                    <div>{{ item.withdrawAmount || '' }}</div>
                  </div>
                  <div class="bottom-wrapper">
                    <div>{{ item.createTime || '' }}</div>
                    <div>{{ withdrawLogStatus(item.withdrawStatus) }}</div>
                  </div>
                  <div v-if="item.withdrawStatus == 3 && item.withdrawRemark" class="fail-reason_wrapper">{{
                    item.withdrawRemark || '' }}</div>
                </div>
              </div>
            </van-list>
          </div>
        </div>
      </van-tab>
      <van-tab title="交易提现" index="1">
        <div class="trade-withdraw">
          <div class="trade-withdraw__top">
            <img src="~assets/images/common/ic_money.png" />
            <div class="text">可提现余额: ¥{{ canWithdrawTotalMoney || 0 }}</div>
          </div>
          <div class="trade-withdraw__input-wrapper">
            <div class="text">¥</div>
            <van-field v-model="powerBankWidthdrawAmount" placeholder="请输入金额" size="large" type="number"
              :rules="[{ required: true, message: '请输入金额' }]" />
          </div>
          <div>
            <van-button round block native-type="submit" class="trade-withdraw__btn submit-btn dialog-btn"
              :class="powerBankWidthdrawAmount > 0 ? 'submit-btn3' : 'submit-btn2'"
              @click="onYsWithdrawConfirm">提现</van-button>
          </div>
          <div class="text-tip_wrapper">
            <div class="text-tip">
              {{ withdrawConfigInfo }}
              <!-- 手动提现手续费：5000以下<span class="text-tip_money">1</span>元一笔5000以上提现金额的<span class="text-tip_money">0.02%</span> -->
            </div>
          </div>
        </div>
        <div class="withdraw-log_title">
          <img src="~assets/images/common/line.png" alt />
          <span>提现记录</span>
        </div>
        <div class="withdraw-list">
          <div>
            <van-list v-model="loading" :finished="finished" :immediate-check="false" :offset="10" finished-text="没有更多了"
              @load="onLoad">
              <div v-for="item in withdrawLogList" :key="item.id" class="withdraw-log_item">
                <img src="~assets/images/common/ic_withdraw_log.png" />
                <div class="content-wrapper">
                  <div class="top-wrapper">
                    <div>提现</div>
                    <div>{{ item.amount || '' }}</div>
                  </div>
                  <div class="bottom-wrapper">
                    <div>{{ item.createTime || '' }}</div>
                    <div>{{ withdrawLogStatus(item.withdrawStatus) }}</div>
                  </div>
                  <div v-if="item.withdrawStatus == 2 && item.remark" class="fail-reason_wrapper">{{ item.remark || ''
                    }}</div>
                </div>
              </div>
            </van-list>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <foot-tabbar v-show="hidshow" />
  </div>
</template>
<script>
import FootTabbar from '@/components/FootTabbar'
import {
  deviceSn,
  apply,
  wechatAuthInfo,
  alipayAuthInfo,
  wxAuth,
  merchantInfo,
  userInfo,
  getYsBalance,
  ysWithdrawApply,
  getDeviceRewardConfig,
  getSettlePayConfig,
  getWithdrawLogList,
  getWithdrawConfig
} from 'api/common'

import { getPowerbankWithdrawLogList, postPowerbankWithdraw } from 'api/powerbank'
export default {
  data() {
    return {
      tabIndex: 0,
      tabs: ['申请提现', '交易提现'],
      formData: {
        deviveList: [],
        logisticsOrderNo: '',
        logisticsCompany: '',
        merchantId: '',
        packageNum: 1,
      },
      merchant: {},
      showList: [],
      orderNo: '',
      company: '',
      show: false,
      list: [],
      result: [], //选的设备
      userType: localStorage.getItem('userType'),
      merchantId: '',
      merchantInfo: '',
      resultShow: false,
      agreePickup: false,
      agreeReturn: false,
      showDeviceReward: false,
      docmHeight: '0', //默认屏幕高度
      showHeight: '0', //实时屏幕高度
      hidshow: true, //显示或者隐藏footer,
      addressInfo: '',
      totalMoney: '',
      canWithdrawTotalMoney: '',
      widthdrawAmount: '',
      powerBankCanWithdrawTotalMoney: '',
      powerBankWidthdrawAmount: '',
      aliPayOpen: false,
      wxPayOpen: false,

      loading: false,
      powerBankLoading: false,
      finished: false,
      powerBankFinished: false,
      page: 1,
      powerbankPage: 1,
      pageSize: 10,
      withdrawLogList: [],
      powerbankWithdrawLogList: [],
      withdrawConfig: {}
    }
  },
  created() {
    this.getSn()
    this.getYsBalance()
    this.getDeviceRewardConfig()
    this.getWithdrawLogList()
    this.getMerchantInfo()
    this.loadPowerbankWithdrawLogList()
    this.getWithdrawConfig()
    let code = this.$route.query.code
    if (code) {
      this.doWxAuth(code)
    }
  },
  mounted() {
    this.merchant = JSON.parse(localStorage.getItem('payInfo'))
    this.formData.merchantId = this.merchant.id
    //默认屏幕高度
    this.docmHeight = document.documentElement.clientHeight
    window.onresize = () => {
      return (() => {
        // 实时屏幕高度
        this.showHeight = document.body.clientHeight + 52
      })()
    }
    this.addressInfo = this.$store.state.addressInfo
    if (!this.addressInfo) {
      const merchant = JSON.parse(localStorage.getItem('payInfo'))
      console.log('merchant', merchant)
      let info = {
        name: merchant.name,
        phone: merchant.consumer,
        addressDetail: merchant.address,
      }
      if (merchant.area) {
        const areas = merchant.area.split('/')
        info.province = areas.length >= 1 ? areas[0] : ''
        info.city = areas.length >= 2 ? areas[1] : ''
        info.county = areas.length >= 3 ? areas[2] : ''
      }
      this.addressInfo = info
      this.$store.commit('setAddressInfo', info)
    }
    console.log(this.addressInfo)
    this.getSettlePayConfig()
  },
  computed: {
    withdrawLogStatus() {
      return status => {
        return status === 0 ? '申请中' : status === 1 ? '审核通过' : status === 2 ? '审核不通过' : ''
      }
    },
    withdrawConfigInfo() {
      const infoList = {
         1:`提现手续费:提现金额的${this.withdrawConfig.taxPoint}%` ,
         2:`提现手续费:提现金额的${this.withdrawConfig.handlingCharge}%` ,
         3:`提现手续费:每笔${this.withdrawConfig.handlingCharge}+提现金额的${this.withdrawConfig.taxPoint}%` 
      }
      return infoList[this.withdrawConfig.commissionMode]
    }
  },
  watch: {
    showHeight() {
      if (this.docmHeight > this.showHeight) {
        this.hidshow = false
      } else {
        this.hidshow = true
      }
    },
  },
  methods: {
    getWithdrawConfig() {
      getWithdrawConfig().then(res => {
        this.withdrawConfig = res.data
      })
    },
    // 获取结算配置
    getSettlePayConfig() {
      getSettlePayConfig().then(res => {
        const { aliPayOpen, wxPayOpen } = res.data
        this.aliPayOpen = aliPayOpen
        this.wxPayOpen = wxPayOpen
      })
    },
    getYsBalance() {
      getYsBalance().then(res => {
        const { accountTotalAmount, accountSettledUnpaidAmount } = res.data
        this.totalMoney = accountTotalAmount
        this.canWithdrawTotalMoney = accountSettledUnpaidAmount
      })
    },
    getMerchantInfo() {
      const func = this.userType == 2 ? userInfo : merchantInfo
      func().then(res => {
        if (res.code === 200) {
          if (this.userType == 2) {
            const { merchant, merchantStore } = res.data
            this.merchantInfo = merchant
            localStorage.setItem('payInfo', JSON.stringify(merchant))
            localStorage.setItem('merchantStore', JSON.stringify(merchantStore))
          } else {
            this.merchantInfo = res.data
            localStorage.setItem('payInfo', JSON.stringify(res.data))
          }
        }
        console.log('merchantInfo', this.merchantInfo);
      })
    },
    toEditAddress() {
      this.$router.push({ path: 'editAddress' })
    },
    // 查询设备SN
    getSn() {
      deviceSn().then(res => {
        if (res.code === 200) {
          this.list = res.data
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    doWxAuth(code) {
      let params = { code: code }
      wxAuth(params).then(res => {
        this.$toast({
          type: 'success',
          message: '微信授权成功',
        })
      })
    },
    // 提现按钮
    onSubmit() {
      const that = this

      if (this.formData.deviveList.length > 0) {
        // 微信和支付宝开关都没打开的时候，让后台返回错误信息
        if (!this.wxPayOpen && !this.aliPayOpen) {
          this.submitApplay()
          return
        }

        Promise.all([wechatAuthInfo(), alipayAuthInfo()])
          .then(res => {
            const wxRes = res[0]
            const aliRes = res[1]

            // 获取微信和支付宝授权信息都失败了
            if (wxRes.code != 200 && aliRes.code != 200) {
              this.$toast.fail('提交申请失败')
              return
            }

            // 只要有一个绑定了就可以提现
            if ((this.wxPayOpen && wxRes.data) || (this.aliPayOpen && aliRes.data)) {
              this.submitApplay()
              return
            }

            const isWxUnbind = this.wxPayOpen && !wxRes.data
            const isAliUnbind = this.aliPayOpen && !aliRes.data
            let channel = isWxUnbind ? '微信' : isAliUnbind ? '支付宝' : ''
            let route = isWxUnbind ? '/bindWx' : isAliUnbind ? '/bindAlipay' : ''

            if (channel && route) {
              this.$dialog
                .confirm({
                  title: `${channel}授权提示`,
                  message: `是否${channel}授权`,
                })
                .then(() => {
                  this.$router.push(route)
                })
            } else {
              this.$toast.fail('提交申请失败')
            }
          })
          .catch(e => this.$toast.fail('提交申请失败'))
      } else {
        this.$toast.fail('寄回设备不能为空')
      }
    },
    // 提交申请
    submitApplay() {
      let params = {
        deviveList: this.formData.deviveList,
        sendBack: this.agreeReturn,
        pickup: !this.agreeReturn ? false : this.agreePickup,
      }
      // 如果不寄回
      if (params.sendBack) {
        params.totalNumber = this.formData.packageNum
      }
      if (params.pickup) {
        params = {
          ...params,
          address: this.addressInfo.addressDetail,
          province: this.addressInfo.province,
          city: this.addressInfo.city,
          county: this.addressInfo.county,
          name: this.addressInfo.name,
          phone: this.addressInfo.phone,
        }
      }
      console.log('submitApplay', params)
      apply(params)
        .then(res => {
          if (res.code === 200) {
            this.resultShow = true
          } else {
            this.$toast.fail(res.msg)
          }
        })
        .catch(e => {
          this.$toast.fail('提交申请失败')
        })
    },
    onDialogConfirm() {
      this.resultShow = false
      this.formData.deviveList = []
      this.formData.logisticsOrderNo = ''
      this.formData.logisticsCompany = ''
      this.showList = []
    },
    // 跳转到订单页面
    toOrder() {
      this.$router.push('/order')
    },
    // 点击请寄回设备
    showPopup() {
      this.show = true
    },
    // 点击弹框确定
    onConfirm() {
      let newArr = []
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < this.formData.deviveList.length; j++) {
          if (this.list[i].id === this.formData.deviveList[j]) {
            newArr.push(this.list[i])
          }
        }
      }
      this.showList = newArr
      this.show = !this.show
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle()
    },
    getDeviceRewardConfig() {
      getDeviceRewardConfig().then(res => {
        console.log('getDeviceRewardConfig', res)
        if (res.code == 200) {
          this.showDeviceReward = res.data.openSwitch
        }
      })
    },
    onYsWithdrawConfirm() {
      if (this.widthdrawAmount <= 0) return
      this.$dialog
        .confirm({
          title: '提示',
          message: '确定要发起提现吗?',
        })
        .then(() => {
          ysWithdrawApply(this.widthdrawAmount)
            .then(res => {
              if (res.code == 200) {
                this.$toast.success('提交申请成功')
                this.getYsBalance()
                this.widthdrawAmount = ''
              } else {
                this.$dialog.confirm({
                  title: '提交申请失败',
                  message: res.msg,
                })
              }
            })
            .catch(e => {
              this.$dialog.confirm({
                title: '提交申请失败',
                message: e.msg,
              })
            })
        })
    },
    getWithdrawLogList() {
      getWithdrawLogList({ page: this.page, pageSize: this.pageSize }).then(res => {
        if (res.code === 200) {
          const { list, total } = res.data
          this.loading = false
          if (list == null || list.length === 0) {
            // 加载结束
            this.finished = true
            return
          }

          // 将新数据与老数据进行合并
          this.withdrawLogList = this.withdrawLogList.concat(list)

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.withdrawLogList.length >= total) {
            this.finished = true
          }
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    onLoad() {
      this.page++
      this.getWithdrawLogList()
    },
    onPowerbankLoadMore() {
      this.powerbankPage++
      this.loadPowerbankWithdrawLogList()
    },
    loadPowerbankWithdrawLogList() {
      const params = {
        page: this.powerbankPage,
        pageSize: 10,
      }
      getPowerbankWithdrawLogList(params).then(res => {
        this.powerBankLoading = false
        const { code, data } = res
        if (code != 200) {
          this.$toast.fail(res.msg)
          return
        }
        const list = data.list || []

        if (list.length === 0) {
          this.powerBankFinished = true
        }

        this.powerbankWithdrawLogList = params.page == 1 ? list : this.powerbankWithdrawLogList.concat(list)
      })
    },
    onPowerbankWithdrawConfirm() {
      if (this.powerBankWidthdrawAmount > this.merchantInfo?.pbWithdrawAbleAmount) {
        return
      }

      postPowerbankWithdraw({ withdrawAmount: this.powerBankWidthdrawAmount }).then(() => {
        if (res.code == 200) {
          this.$toast.success('提交申请成功')
          this.getMerchantInfo()
        } else {
          this.$dialog.confirm({
            title: '提交申请失败',
            message: e.msg,
          })
        }
      })
    },
  },
  components: {
    FootTabbar,
  },
}
</script>

<style lang="less" scoped>
.m-t-10 {
  margin-top: 10px;
  color: gray;
}

@media screen and (min-width: 539px) {
  /deep/.van-popup--bottom {
    right: none;
    left: 50%;
    width: 375px;
    margin-left: -187.5px;
  }

  /deep/.van-overlay {
    right: none;
    left: 50%;
    width: 375px;
    margin-left: -187.5px;
  }

  .submit-btn2 {
    opacity: 0.3;
  }

  .submit-btn {
    // width: 345px;
    width: 100%;
    margin: 8px auto;
  }

  .submit-btn3 {
    opacity: 1;
  }
}

.package-num_wrapper {
  background-color: #fff;
}

.return {
  margin-top: 10px;
}

.address {
  background: #fff;
  padding: 15px;

  .m-l-20 {
    margin-left: 20px;
  }

  img {
    margin-left: 20px;
  }
}

.submit-btn2 {
  opacity: 0.3;
}

.submit-btn3 {
  opacity: 1;
}

/deep/ .van-checkbox-group {
  padding-bottom: 0 !important;
}

.withdrawal {
  font-size: 14px;

  .withdrawal-box {

    // padding: 20px 15px 15px 15px;
    .form {
      .van-cell {
        font-size: 14px;
        padding: 15px;

        /deep/.van-field__label,
        .van-cell__value--alone {
          color: rgba(30, 36, 49, 0.6);
        }

        /deep/.van-field__control {
          color: #1e2431;
        }

        /deep/.van-field__error-message {
          display: none;
        }
      }

      /deep/.van-cell::after {
        border-bottom: 1px solid rgba(112, 112, 112, 0.1);
      }

      .title {
        background: #fff;
        padding: 15px 15px 0 15px;

        p {
          font-size: 16px;
          color: #1e2431;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(112, 112, 112, 0.1);
        }
      }

      .list {
        background: #fff;
        padding: 0 15px;
        overflow: hidden;

        .list-item {
          border-bottom: 1px solid rgba(112, 112, 112, 0.1);

          .item {
            color: #1e2431;
            margin: 10px 0;
            font-size: 12px;
            margin-top: 12px;
          }
        }
      }

      .tip {
        background: #fff;
        font-size: 12px;
        color: rgba(253, 110, 5, 0.6);
        padding: 15px;
      }
    }

    /deep/.van-checkbox-group {
      padding-bottom: 60px;
    }

    .popup-title {
      font-size: 15px;
      color: #1e2431;
      text-align: center;
      padding: 17px 0 13px 0;
    }

    .popup-item {
      padding-right: 10px;
    }

    .btn-box {
      position: fixed;
      padding: 0 15px;
      box-sizing: border-box;
      width: 375px;
      left: 50%;
      margin-left: -187.5px;
      bottom: 0;
      background: #ffffff;

      .commit-btn {
        margin: 8px 0;
      }
    }
  }

  .dialog-box {
    text-align: center;

    img {
      width: 43px;
      height: 43px;
      margin: 30px auto 20px auto;
    }

    .dialog-btn {
      height: 36px;
      width: 273px;
      margin: 26px auto 34px auto;
    }
  }
}

.item-statistics {
  display: flex;
  align-items: center;
  margin-top: 5px;
  background: #f9f9f9;
  border-radius: 5px;
  padding: 8px 10px;

  &_item {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .prefix {
    flex-shrink: 0;
    font-size: 12px;
    color: #999999;
    margin-right: 4px;
  }
}

/deep/.van-tab--active:after {
  position: absolute;
  width: 29px;
  height: 5px;
  top: 37px;
  background: linear-gradient(89deg, rgba(253, 110, 5, 0.66) 10%, #fd6e05 81%);
  content: '';
  border-radius: 3px;
}

/deep/.van-tabs__line {
  display: none;
}

/deep/.van-tabs__content {
  margin: 15px;
}

/deep/.van-field {
  font-size: 25px;
  font-weight: 500;
  color: #cccccc;
  background: none;
}

.trade-withdraw {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px;
}

.trade-withdraw__top {
  display: flex;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    color: #1e2431;
    margin-left: 8px;
  }
}

.trade-withdraw__input-wrapper {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 5px;
  margin-top: 18px;
  padding: 0 14px;

  .text {
    font-size: 25px;
    font-weight: 400;
    color: #333333;
  }
}

.trade-withdraw__btn {
  margin-top: 20px;
}

.mtb {
  margin: 14px 0 16px 0;
}

.order-title {
  img {
    height: 22px;
    width: 22px;
    margin-right: 7px;
  }
}

.text-tip_wrapper {
  padding: 10px 0;
}

.text-tip {
  color: #8a8a8a;
  font-size: 11px;

  &_money {
    color: #fd6e05;
  }
}

.withdraw-log_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  padding: 20px 0;

  img {
    height: 14px;
    margin-right: 10px;
  }

  .indicator {
    width: 2px;
    height: 15px;
    background-color: #fd710a;
    margin-right: 10px;
  }
}

.withdraw-list {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px 15px 100px 15px;

  .withdraw-log_item {
    display: flex;
    padding: 12px 0;
    border-bottom: 1px solid #f8f8f8;

    img {
      width: 49px;
      height: 44px;
      flex-shrink: 0;
    }

    .content-wrapper {
      width: 100%;
      flex: 1;
      margin-left: 8px;
    }

    .top-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      color: #333;
    }

    .bottom-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      font-size: 11px;
      color: #333;
      opacity: 0.6;
    }

    .fail-reason_wrapper {
      background: rgba(255, 0, 0, 0.05);
      margin-top: 10px;
      padding: 9px;
      color: rgba(255, 0, 0, 0.7);
    }
  }
}
</style>
